<template>
  <div class="examPgWrapper">
    <p v-if="language === 'en'" class="examTitle">Question {{ this.examPg + 1 }}</p>
    <p v-if="language === 'zh'" class="examTitle">問題 {{ this.examPg + 1 }}</p>
    <div class="examTextWrapper">
      <div v-if="language === 'zh'" class="examSubTitle">
        <p v-for="(question, index) in examData.zh.question" :key="index">
          {{ question }}
        </p>
      </div>
      <div v-if="language === 'en'" class="examSubTitle">
        <p v-for="(question, index) in examData.en.question" :key="index">
          {{ question }}
        </p>
      </div>
    </div>
    <div class="answerWrapper">
      <div class="answerA answerBtnWrapper" @click="checkAnswer('a')">
        <div class="answerABtn answerBtn" ref="answerABtn">
          <p>A</p>
        </div>
        <div class="answerAText answerText" ref="answerAText">
          <p v-if="language === 'zh'">
            {{ examData.zh.answer.a }}
          </p>
          <p v-if="language === 'en'">
            {{ examData.en.answer.a }}
          </p>
        </div>
      </div>
      <div class="answerB answerBtnWrapper" @click="checkAnswer('b')">
        <div class="answerBBtn answerBtn" ref="answerBBtn">
          <p>B</p>
        </div>
        <div class="answerBText answerText" ref="answerBText">
          <p v-if="language === 'zh'">
            {{ examData.zh.answer.b }}
          </p>
          <p v-if="language === 'en'">
            {{ examData.en.answer.b }}
          </p>
        </div>
      </div>
      <div class="answerC answerBtnWrapper" @click="checkAnswer('c')" v-if="examData.en.answer.c">
        <div class="answerCBtn answerBtn" ref="answerCBtn">
          <p>C</p>
        </div>
        <div class="answerCText answerText" ref="answerCText">
          <p v-if="language === 'zh'">
            {{ examData.zh.answer.c }}
          </p>
          <p v-if="language === 'en'">
            {{ examData.en.answer.c }}
          </p>
        </div>
      </div>
      <div class="answerD answerBtnWrapper" @click="checkAnswer('d')" v-if="examData.en.answer.d">
        <div class="answerDBtn answerBtn" ref="answerDBtn">
          <p>D</p>
        </div>
        <div class="answerDText answerText" ref="answerDText">
          <p v-if="language === 'zh'">
            {{ examData.zh.answer.d }}
          </p>
          <p v-if="language === 'en'">
            {{ examData.en.answer.d }}
          </p>
        </div>
      </div>
    </div>    
  </div>
</template>

<script>
import firebase from "firebase";
import { db } from '@/components/firebase.js';
import dbFunctions from '@/components/functions/database.js'
export default {
  name: 'ExamQ',
  data() {
    return {
      selectedAnswer: null,
    }
  },
  props: {
    examData: Object
  },
  computed: {
    //For dubugging only:
    // newExamData() {
    //   console.log(this.examData.id);
    //   return this.examData.id
    // },
    language() {
      return this.$store.getters.getLanguage;
    },
    examPg() {
      return this.$store.getters.getExamPg;
    },
    userEmail() {
      return this.$store.getters.getUserEmail;
    },
    examResult() {
      return this.$store.getters.getExamResult;
    },
    examTempScore() {
      return this.$store.getters.getExamTempScore;
    },
  },
  methods: {
    checkAnswer(data) {
      setTimeout(() => {
        this.$emit('nextQuestion')
      }, 1500)
      if (this.selectedAnswer === null) {
        this.selectedAnswer = data;
        db.collection("exam").doc("examAnswers")
        .get()
        .then((doc) => {
          if(doc.exists) {
            if(doc.data()[this.examData.id] === data) {
              if(data === 'a') {
                this.$refs.answerABtn.classList.add('rightAnswer');
                this.$refs.answerAText.classList.add('rightAnswer');
                // this.nextQuestion();
                this.updateExamScore(this.examTempScore+1);
              }
              if(data === 'b') {
                this.$refs.answerBBtn.classList.add('rightAnswer');
                this.$refs.answerBText.classList.add('rightAnswer');
                // this.nextQuestion();
                this.updateExamScore(this.examTempScore+1);
              }
              if(data === 'c') {
                this.$refs.answerCBtn.classList.add('rightAnswer');
                this.$refs.answerCText.classList.add('rightAnswer');
                // this.nextQuestion();
                this.updateExamScore(this.examTempScore+1);
              }
              if(data === 'd') {
                this.$refs.answerDBtn.classList.add('rightAnswer');
                this.$refs.answerDText.classList.add('rightAnswer');
                // this.nextQuestion();
                this.updateExamScore(this.examTempScore+1);
              }
            }
            else {
              if(data === 'a') {
                this.$refs.answerABtn.classList.add('wrongAnswer');
                this.$refs.answerAText.classList.add('wrongAnswer');
                this.showRightAnswer(doc.data().q1);
                // this.nextQuestion();
                this.updateExamScore(this.examTempScore);
              }
              if(data === 'b') {
                this.$refs.answerBBtn.classList.add('wrongAnswer');
                this.$refs.answerBText.classList.add('wrongAnswer');
                this.showRightAnswer(doc.data().q1);
                // this.nextQuestion();
                this.updateExamScore(this.examTempScore);
              }
              if(data === 'c') {
                this.$refs.answerCBtn.classList.add('wrongAnswer');
                this.$refs.answerCText.classList.add('wrongAnswer');
                this.showRightAnswer(doc.data().q1);
                // this.nextQuestion();
                this.updateExamScore(this.examTempScore);
              }
              if(data === 'd') {
                this.$refs.answerDBtn.classList.add('wrongAnswer');
                this.$refs.answerDText.classList.add('wrongAnswer');
                this.showRightAnswer(doc.data().q1);
                // this.nextQuestion();
                this.updateExamScore(this.examTempScore);
              }
            }
          }
        })
      }
    },
    showRightAnswer(data) {
      if(data === 'a') {
        this.$refs.answerABtn.classList.add('rightAnswer');
        this.$refs.answerAText.classList.add('rightAnswer');
      }
      if(data === 'b') {
        this.$refs.answerBBtn.classList.add('rightAnswer');
        this.$refs.answerBText.classList.add('rightAnswer');
      }
      if(data === 'c') {
        this.$refs.answerCBtn.classList.add('rightAnswer');
        this.$refs.answerCText.classList.add('rightAnswer');
      }
      if(data === 'd') {
        this.$refs.answerDBtn.classList.add('rightAnswer');
        this.$refs.answerDText.classList.add('rightAnswer');
      }
    },
    resetAnswer() {
      const answerBtn = document.querySelectorAll(".answerBtn");
      const answerText = document.querySelectorAll(".answerText");
      answerBtn.forEach(btn => {
        btn.classList.remove('rightAnswer');
        btn.classList.remove('wrongAnswer');
      })
      answerText.forEach(btn => {
        btn.classList.remove('rightAnswer');
        btn.classList.remove('wrongAnswer');
      })
    },
    updateExamScore(data) {
      db.collection("user_data").doc(this.userEmail)
      .update({
        "examTempScore": data
      })
      .then(() => {
        if(this.examPg === 19) {
          this.updateServerExam(data);
        }
      })
      .catch((err) => {
        console.error(err)
      })
    },
    nextQuestion() {
      this.$store.commit('examAnswered', true);
    },
    async generateRef(timestamp, num) {
      const userData = await dbFunctions.getDataByUser(this.userEmail);
      if (userData.archivedCertificateRef) {
        if (userData.archivedCertificateRef.indexOf('-') > -1) {
          const dashRef = userData.archivedCertificateRef.split('-')[1];
          const originalRef = userData.archivedCertificateRef.split('-', 1)[0];
          const newDashRef = Number(dashRef) + 1;
          return originalRef + '-' + newDashRef;
        } else {
          return userData.archivedCertificateRef + '-1'
        }
      } else {
        if (!userData.certificateRef || userData.certificateRe === null) {
          const year = new Date(timestamp*1000).getFullYear();
          const certByYear = await dbFunctions.getCertRefByYear(year);
          const numCount = num + 1;
          const newRefOrder = certByYear.length + numCount;
          let refNum = newRefOrder.toString().padStart(5, '0')
          let countryCode;
          if (userData.shopCountry === 'Hong Kong') {
            countryCode = 'A'
          } else {
            countryCode = 'B'
          }
          const ref = `${year}${refNum}${countryCode}`;
          const refCheck = await dbFunctions.checkCertRef(ref);
          if (refCheck) {
            return ref;
          } else {
            return this.generateRef(timestamp, numCount);
          }
        } else {
          return userData.certificateRef;
        }
      }
    },
    async updateServerExam(data) {
      const serverTimestamp = await firebase.firestore.Timestamp.now();
      const timestamp = serverTimestamp.seconds; 
      if(data > this.examResult) {
        const userDb = await db.collection("user_data").doc(this.userEmail)
        const userData = await userDb.get();
        if(data >= 15 && userData.data().examComplete === false) {
          this.$emit('examPassed', true)
          const certRef = await this.generateRef(timestamp, 0);
          userDb
          .update({
            "examResult": data,
            "examComplete": true,
            "examCompleteDate": timestamp,
            "examExpiredDate": timestamp + 31449600,
            "certificateRef": certRef
          })
          .then(() => {
          })
          .catch((err) => {
            console.log("Update exam result error: " + err);
          })
        }
        else {
          this.$emit('examPassed', false)
          userDb
          .update({
            "examResult": data
          })
          .then(() => {
          })
          .catch((err) => {
            console.log("Update exam result error: " + err);
          })
        }
      } else {
        this.$emit('examPassed', false)
      }
    }
  },
  watch: {
    examPg() {
      this.selectedAnswer = null;
      this.resetAnswer()
    },
    //For dugging only:
    // examData() {
    //   console.log(this.examData.id);
    // }
  },
}

</script>

<style lang="scss" scoped>
.examPgWrapper {
  width: 100%;
  height: 100%;
  overflow: auto;
  display: grid;
  grid-template-columns: 8% 84% 8%;
  // grid-template-rows: ;
  background-color: white;
  border-radius: 1.5em;
  justify-self: center;
  align-self: center;
}
.examTitle {
  grid-column: 2/3;
  grid-row: 1/2;
  font-size: 16pt;
  font-weight: bold;
  text-align: center;
  align-self: center;
  margin: 1em 0 0.5em 0;

  @media only screen and (min-width: 480px) {
    font-size: 18pt;
    margin: 4% 0 2% 0;
  }

  @media only screen and (min-width: 768px) {
    font-size: 24pt;
    margin: 4% 0 2% 0;
  }

  @media only screen and (min-width: 1024px) {
    font-size: 26pt;
  }
}
.examSubTitle {
  font-size: 10pt;

  @media only screen and (min-width: 768px) {
    font-size: 16pt;
  }

  @media only screen and (min-width: 1024px) {
    font-size: 18pt;
  }

  p:first-child {
    font-weight: bold;
    margin: 1em 0;
  }
  p {
    margin: 0;
  }
  p:last-child {
    margin: 0 0 1em 0;
  }
}

.examTextWrapper {
  grid-column: 2/3;
  grid-row: 2/3;
  overflow: auto;
  align-self: start;
  /* margin-bottom: 5%; */
  height: 100%;
}
.examText {
  text-align: left;
  font-size: 18pt;
  font-weight: 500;
  margin: 0;
}
.examListText {
  text-align: left;
  font-size: 18pt;
  margin: 10pt 0 10pt 0;
  margin: 0;
}
.examFooter {
  grid-column: 2/3;
  grid-row: 4/5;
  height: 3.5em;
}
.examContentImg {
  height: 305px;
  float: right;
  margin-left: 1%;
  z-index: 10;
}
.examContentImg2 {
  height: 285px;
  float: right;
  margin-left: 1%;
  z-index: 10;
}
.examListText {
  text-align: left;
  font-size: 18pt;
  margin: 20pt 0 20pt 0;
}
.reference {
  color: #7890A9;
}
.answerWrapper {
  grid-column: 2/3;
  grid-row: 3/4;
  overflow: auto;
  align-self: start;
  justify-self: center;
  width: 100%;
  display: grid;
  margin: 0 0 1.4em 0;
  gap: 0.4em;

  @media only screen and (min-width: 768px) {
    margin: 0 0 2em 0;
  }

  @media only screen and (min-width: 1024px) {
    width: 90%;
  }
}
.answerBtnWrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 4em 1em auto;

  @media only screen and (min-width: 768px) {
    grid-template-columns: 5em 1em auto;
  }
}
.answerBtn {
  grid-column: 1/2;
  grid-row: 1/2;
  height: auto;
  // width: 5em;
  background-color: #D3D3D3;
  border-radius: 1em;
  display: grid;
  cursor: pointer;
}
.answerBtn p {
  font-size: 12pt;
  font-weight: 500;
  align-self: center;
  justify-self: center;

  @media only screen and (min-width: 768px) {
    font-size: 14pt;
  }

  @media only screen and (min-width: 1280px) {
    font-size: 16pt;
  }
}
.answerText {
  grid-column: 3/4;
  grid-row: 1/2;
  /* width: 100%; */
  display: grid;
  background-color: #D3D3D3;
  border-radius: 1em;
  cursor: pointer;
}
.answerText p {
  font-size: 10pt;
  font-weight: 500;
  align-self: center;
  justify-self: start;
  margin: 1em 1.2em 1em 1.2em;

  @media only screen and (min-width: 768px) {
    font-size: 12pt;
  }

  @media only screen and (min-width: 1024px) {
    font-size: 14pt;
  }

  @media only screen and (min-width: 1280px) {
    font-size: 16pt;
  }
}
.answerA {
  grid-column: 1/2;
  grid-row: 1/2;
}
.answerB {
  grid-column: 1/2;
  grid-row: 3/4;
}
.answerC {
  grid-column: 1/2;
  grid-row: 5/6;
}
.answerD {
  grid-column: 1/2;
  grid-row: 7/8;
}
.rightAnswer {
  color: white!important;
  background-color: #33D33F;
  font-weight: bold!important;
}
.wrongAnswer {
  color: white!important;
  background-color: #F43434;
  font-weight: bold!important;
}

// @media only screen and (max-height: 960px)  and (orientation: landscape) {
//   .examTitle {
//     font-size: 20pt;
//   }
//   .examSubTitle {
//     font-size: 16pt;
//   }
//   .examSubTitle2 {
//     font-size: 16pt;
//   }
//   .examText {
//     font-size: 14pt;
//   }
//   .examListText {
//     font-size: 14pt;
//   }
//   .examContentImg {
//     height: 280px;
//   }
//   .examContentImg2 {
//     height: 250px;
//   }
//   .answerBtn p {
//     font-size: 14pt;
//   }
//   .answerText p {
//     font-size: 14pt;
//   }
// }
</style>
