<template>
  <div class="signUpWrapper">
    <div class="signUpContent">
      <div class="signUpTitle">
        <!-- <h2>FORM</h2> -->
        <p v-if="language === 'zh'">證書上只會印上你的名字和身份證頭四位數字以核實身份。其他資料只作行政用途。沒有你的同意，第三方不會獲得你的個人資料。</p>
        <p v-if="language === 'en'">Only your name and first four digits of your ID will be printed on the certificate. Other information collected is used for administrative purposes only. No third party will obtain the personal information provided below without your authorisation.</p>
      </div>
      <img src="@/assets/close_btn_white.svg" class="signUpCloseBtn" @click="signUpCloseBtn">
      <div class="signUpInputWrapper">
        <div class="inputWrapper">
          <p class="inputTitle" v-if="language === 'zh'">*英文名 (與官方文件一致) </p>
          <p class="inputTitle" v-if="language === 'en'">*English Name (Legal name as shown on official document) </p>
          <input class="firstNameInput textInput" ref="englishNameInput" name="englishName" type="text" v-model="englishName">
          <div class="warningTextWrapper">
            <p class="warningText2" v-if="language === 'en'">Enter your full first name, not just initials. This will be displayed on the certificate.</p>
            <p class="warningText" v-if="language === 'zh'" v-show="englishNameWarningText">* 此格為空</p>
            <p class="warningText" v-if="language === 'en'" v-show="englishNameWarningText">* This filed is empty</p>
          </div>
        </div>
        <div class="inputWrapper">
          <p class="inputTitle" v-if="language === 'zh'">中文名 (與官方文件一致) </p>
          <p class="inputTitle" v-if="language === 'en'">Chinese Name (Legal name as shown on official document) </p>
          <input class="lastNameInput textInput" ref="lastNameInput" name="chineseName" type="text" v-model="chineseName">
          <div class="warningTextWrapper">
            <p class="warningText" v-if="language === 'zh'" v-show="chineseNameWarningText">* 此格為空</p>
            <p class="warningText" v-if="language === 'en'" v-show="chineseNameWarningText">* This filed is empty</p>
          </div>
        </div>
        <div class="inputWrapper">
          <p class="inputTitle" v-if="language === 'zh'">*藝術家名</p>
          <p class="inputTitle" v-if="language === 'en'">*Artist Name</p>
          <input class="lastNameInput textInput" ref="artisNameInput" name="artisName" type="text" v-model="artisName">
          <div class="warningTextWrapper">
            <p class="warningText" v-if="language === 'zh'" v-show="artisNameWarningText">* 此格為空</p>
            <p class="warningText" v-if="language === 'en'" v-show="artisNameWarningText">* This filed is empty</p>
          </div>
        </div>
        <div class="inputWrapper">
          <p class="inputTitle" v-if="language === 'zh'">出生日期</p>
          <p class="inputTitle" v-if="language === 'en'">Date of Birth</p>
          <div class="dataOfBirth">
            <div class="shortInputWrapper">
              <p v-if="language === 'zh'">日</p>
              <p v-if="language === 'en'">Day</p>
              <input class="textInput numberInput" type="number" name="birthDay" v-model="birth.day">
            </div>
            <div class="shortInputWrapper">
              <p v-if="language === 'zh'">月</p>
              <p v-if="language === 'en'">Month</p>
              <input class="textInput numberInput" type="number" name="birthMonth" v-model="birth.month">
            </div>
            <div class="shortInputWrapper">
              <p v-if="language === 'zh'">年</p>
              <p v-if="language === 'en'">Year</p>
              <input class="yearWrapper textInput numberInput" type="number" name="birthYear" v-model="birth.year">
            </div>
          </div>
          <div class="warningTextWrapper">
            <p class="warningText" v-if="language === 'zh'" v-show="birthWarningText">* 此格為空</p>
            <p class="warningText" v-if="language === 'en'" v-show="birthWarningText">* This filed is empty</p>
          </div>
        </div>
        <div class="dropDownWrapper inputWrapper">
          <p class="inputTitle" v-if="language === 'zh'">性別</p>
          <p class="inputTitle" v-if="language === 'en'">Gender</p>
          <div class="contactSelectWrapper">
            <button 
              class="contactSelect"
              @click="openOptions('gender')"
            >
              <p>{{ genderSelected }}</p>
              <img src="@/assets/down_icon.svg" alt="Select">
            </button>
          </div>
          <ul class="contactSelectOptions" :class="{ hidden: genderHide }" v-if="language === 'zh'">
            <li 
              v-for="option in genderOptions.zh"
              :key="option"
              @click="selectOption('gender', option)"
            >
              {{ option }}
            </li>
          </ul>
          <ul class="contactSelectOptions" :class="{ hidden: genderHide }" v-if="language === 'en'">
            <li 
              v-for="option in genderOptions.en"
              :key="option"
              @click="selectOption('gender', option)"
            >
              {{ option }}
            </li>
          </ul>
          <div class="warningTextWrapper">
            <p class="warningText" v-if="language === 'zh'" v-show="genderWarningText">* 請選擇</p>
            <p class="warningText" v-if="language === 'en'" v-show="genderWarningText">* Please select one</p>
          </div>
        </div>
        <div class="inputWrapper">
          <p class="inputTitle" v-if="language === 'zh'">*身份證/護照號碼（頭4位數，包括英文字母同號碼）</p>
          <p class="inputTitle" v-if="language === 'en'">*ID/Passport Number (First 4 digits only, include both alphabets and numbers)</p>
          <input class="lastNameInput textInput" ref="passportInput" name="passport" type="text" v-model="passport">
          <div class="warningTextWrapper">
            <p class="warningText" v-if="language === 'zh'" v-show="passportWarningText">* 此格為空</p>
            <p class="warningText" v-if="language === 'en'" v-show="passportWarningText">* This filed is empty</p>
          </div>
        </div>
        <div class="dropDownWrapper inputWrapper">
          <p class="inputTitle" v-if="language === 'zh'">*國籍</p>
          <p class="inputTitle" v-if="language === 'en'">*Nationality</p>
          <div class="contactSelectWrapper">
            <button 
              class="contactSelect"
              @click="openOptions('nationality')"
            >
              <p>{{ nationalitySelected }}</p>
              <img src="@/assets/down_icon.svg" alt="Select">
            </button>
          </div>
          <ul class="contactSelectOptions" :class="{ hidden: nationalityHide }">
            <li 
              v-for="country in countris"
              :key="country.name"
              @click="selectOption('nationality', country.name)"
            >
              {{ country.name }}
            </li>
          </ul>
          <div class="warningTextWrapper">
            <p class="warningText" v-if="language === 'zh'" v-show="nationalityWarningText">* 請選擇</p>
            <p class="warningText" v-if="language === 'en'" v-show="nationalityWarningText">* Please select one</p>
          </div>
        </div>
        <!-- <div class="inputWrapper">
          <p class="inputTitle">E-mail</p>
          <input class="emailInput textInput" ref="emailInput" name="email" type="email" v-model="email">
          <div class="warningTextWrapper">
            <p class="warningText" v-show="emailWarningText">* This filed is empty</p>
            <p class="warningText" v-show="emailWarningText2">* Please enter a valid email address.</p>
          </div>
        </div>
        <div class="inputWrapper">
          <p class="inputTitle">Confirm E-mail</p>
          <input class="confirmEmailInput textInput" ref="confirmEmailInput" name="confirmEmail" type="email" v-model="confirmEmail">
          <div class="warningTextWrapper">
            <p class="warningText" v-show="confirmEmailWarningText">* This filed is empty</p>
            <p class="warningText" v-show="confirmEmailWarningText2">* This does not match your email above.</p>
          </div>
        </div> -->
        <div class="inputWrapper">
          <p class="inputTitle" v-if="language === 'zh'">*聯絡電話（請包括國家代碼）</p>
          <p class="inputTitle" v-if="language === 'en'">*Contact Number (Please include your country code in brackets)</p>
          <input class="lastNameInput textInput" ref="contactInput" name="contact" type="text" v-model="contact">
          <div class="warningTextWrapper">
            <p class="warningText" v-if="language === 'zh'" v-show="contactWarningText">* 此格為空</p>
            <p class="warningText" v-if="language === 'en'" v-show="contactWarningText">* This filed is empty</p>
          </div>
        </div>
        <div class="dropDownWrapper inputWrapper">
          <p class="inputTitle" v-if="language === 'zh'">*職業</p>
          <p class="inputTitle" v-if="language === 'en'">*Profession</p>
          <div class="contactSelectWrapper">
            <button 
              class="contactSelect"
              @click="openOptions('profession')"
            >
              <p>{{ professionSelected }}</p>
              <img src="@/assets/down_icon.svg" alt="Select">
            </button>
          </div>
          <ul class="contactSelectOptions" :class="{ hidden: professionHide }" v-if="language === 'zh'">
            <li 
              v-for="option in professionOptions.zh"
              :key="option"
              @click="selectOption('profession', option)"
            >
              {{ option }}
            </li>
          </ul>
          <ul class="contactSelectOptions" :class="{ hidden: professionHide }" v-if="language === 'en'">
            <li 
              v-for="option in professionOptions.en"
              :key="option"
              @click="selectOption('profession', option)"
            >
              {{ option }}
            </li>
          </ul>
          <div class="warningTextWrapper">
            <p class="warningText" v-if="language === 'zh'" v-show="professionWarningText">* 請選擇</p>
            <p class="warningText" v-if="language === 'en'" v-show="professionWarningText">* Please select one</p>
          </div>
        </div>
        <div class="inputWrapper">
          <p class="inputTitle" v-if="language === 'zh'">*店名</p>
          <p class="inputTitle" v-if="language === 'en'">*Shop Name</p>
          <input class="studioNameInput textInput" ref="studioNameInput" name="studioName" type="text" v-model="studioName">
          <div class="warningTextWrapper">
            <p class="warningText" v-if="language === 'zh'" v-show="studioNameWarningText">* 此格為空</p>
            <p class="warningText" v-if="language === 'en'" v-show="studioNameWarningText">* This filed is empty</p>
          </div>
        </div>
        <div class="inputWrapper">
          <p class="inputTitle" v-if="language === 'zh'">*工作地址（必須能夠接收郵件，你的證書及贈品將會寄至此地址）</p>
          <p class="inputTitle" v-if="language === 'en'">*Mailing address (IMPORTANT: you will receive your certificate at this address)</p>
          <input class="studioAddressInput textInput" ref="studioAddressInput" name="studioAddress" type="text" v-model="studioAddress">
          <div class="warningTextWrapper">
            <p class="warningText" v-if="language === 'zh'" v-show="studioAddressWarningText">* 此格為空</p>
            <p class="warningText" v-if="language === 'en'" v-show="studioAddressWarningText">* This filed is empty</p>
          </div>
        </div>
        <div class="dropDownWrapper inputWrapper">
          <p class="inputTitle" v-if="language === 'zh'">*工作國家</p>
          <p class="inputTitle" v-if="language === 'en'">*In which country do you work at?</p>
          <div class="contactSelectWrapper">
            <button 
              class="contactSelect"
              @click="openOptions('country')"
            >
              <p>{{ countrySelected }}</p>
              <img src="@/assets/down_icon.svg" alt="Select">
            </button>
          </div>
          <ul class="contactSelectOptions" :class="{ hidden: countryHide }">
            <li 
              v-for="country in countris"
              :key="country.name"
              @click="selectOption('country', country.name)"
            >
              {{ country.name }}
            </li>
          </ul>
          <div class="warningTextWrapper">
            <p class="warningText" v-if="language === 'zh'" v-show="countryWarningText">* 請選擇</p>
            <p class="warningText" v-if="language === 'en'" v-show="countryWarningText">* Please select one</p>
          </div>
        </div>
        <div class="inputWrapper">
          <p class="inputTitle" v-if="language === 'zh'">經驗（年）</p>
          <p class="inputTitle" v-if="language === 'en'">Year(s) of Experience</p>
          <input class="studioAddressInput textInput" ref="studioAddressInput" name="yearOfExp" type="number" v-model="yearOfExp">
          <div class="warningTextWrapper">
            <p class="warningText" v-if="language === 'zh'" v-show="yearOfExpWarningText">* 此格為空</p>
            <p class="warningText" v-if="language === 'en'" v-show="yearOfExpWarningText">* This filed is empty</p>
          </div>
        </div>
        <div class="dropDownWrapper inputWrapper">
          <p class="inputTitle" v-if="language === 'zh'">你是從什麽途徑開始進行這個職業的？</p>
          <p class="inputTitle" v-if="language === 'en'">How did you start this profession?</p>
          <div class="contactSelectWrapper">
            <button 
              class="contactSelect"
              @click="openOptions('howStart')"
            >
              <p>{{ howStartSelected }}</p>
              <img src="@/assets/down_icon.svg" alt="Select">
            </button>
          </div>
          <ul class="contactSelectOptions" :class="{ hidden: howStartHide }" v-if="language === 'zh'">
            <li 
              v-for="option in howStartOptions.zh"
              :key="option"
              @click="selectOption('howStart', option)"
            >
              {{ option }}
            </li>
          </ul>
          <ul class="contactSelectOptions" :class="{ hidden: howStartHide }" v-if="language === 'en'">
            <li 
              v-for="option in howStartOptions.en"
              :key="option"
              @click="selectOption('howStart', option)"
            >
              {{ option }}
            </li>
          </ul>
          <div class="warningTextWrapper">
            <p class="warningText" v-if="language === 'zh'" v-show="howStartWarningText">* 請選擇</p>
            <p class="warningText" v-if="language === 'en'" v-show="howStartWarningText">* Please select one</p>
          </div>
        </div>
        <div class="inputWrapper">
          <p class="inputTitle" v-if="language === 'zh'">*作品集</p>
          <p class="inputTitle" v-if="language === 'en'">*Portfolio</p>
          <input class="studioAddressInput textInput" ref="portfolioInput" name="portfolio" type="text" v-model="portfolio">
          <div class="warningTextWrapper">
            <p class="warningText2" v-if="language === 'zh'">請填上你的網頁或作品集鏈接，例如www.instagram.com/jktattoohongkong</p>
            <p class="warningText2" v-if="language === 'en'">Website or social media link, e.g. www.instagram.com/bmtahk</p>
            <p class="warningText" v-if="language === 'zh'" v-show="portfolioWarningText">* 此格為空</p>
            <p class="warningText" v-if="language === 'en'" v-show="portfolioWarningText">* This filed is empty</p>
          </div>
        </div>
      </div>
      <div class="signUpBtnWrapper">
        <div class="signUpBtn" @click="submitSignUpForm">
          <p class="signUpBtnText" v-if="language === 'zh'">付款</p>
          <p class="signUpBtnText" v-if="language === 'en'">PAYMENT</p>
        </div>
        <div class="signUpCancelBtn" @click="signUpCloseBtn">
          <p class="signUpBtnText" v-if="language === 'zh'">取消</p>
          <p class="signUpBtnText" v-if="language === 'en'">CANCEL</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import dbFunctions from '@/components/functions/database.js';
import { db } from '@/components/firebase.js';
import countryList from '@/data/countries.json';
export default {
  name: 'CoursePurchaseForm',
  data() {
    return {
      englishNameWarningText: false,
      chineseNameWarningText: false,
      artisNameWarningText: false,
      birthWarningText: false,
      genderWarningText: false,
      passportWarningText: false,
      nationalityWarningText: false,
      // emailWarningText: false,
      // emailWarningText2: false,
      // confirmEmailWarningText: false,
      // confirmEmailWarningText2: false,
      contactWarningText: false,
      professionWarningText: false,
      studioNameWarningText: false,
      studioAddressWarningText: false,
      countryWarningText: false,
      yearOfExpWarningText: false,
      howStartWarningText: false,
      portfolioWarningText: false,
      selectWarningText: false,
      englishName: "",
      chineseName: "",
      artisName: "",
      email: "",
      confirmEmail: "",
      passport: "",
      contact: "",
      studioName: "",
      studioAddress: "",
      yearOfExp: "",
      portfolio: "",
      birth: {
        day: "",
        month: "",
        year: ""
      },
      checkEmail: false,
      checkPassword: false,
      nationalitySelected: "Please select...",
      nationalityHide: true,
      genderSelected: "Please select...",
      genderHide: true,
      genderOptions: {
        'en': [
          'Male',
          'Female',
          'Other'
        ],
        'zh': [
          '男',
          '女',
          '其他'
        ],
      },
      professionSelected: "Please select...",
      professionHide: true,
      professionOptions: {
        'zh': [
          '紋身師',
          '穿環師',
          '紋繡師',
          '其他'
        ],
        'en': [
          'Tattoo artist',
          'Piercer',
          'Cosmetic tattoo artist',
          'Other artistic body modification practitioner'
        ]
      },
      hideSelect: true,
      countris: countryList,
      countrySelected: "Please select...",
      countryHide: true,
      howStartSelected: "Please select...",
      howStartHide: true,
      howStartOptions: {
        'zh': [
          '學徒',
          '公司培訓',
          '自費課程',
          '自學'
        ],
        'en': [
          'Apprenticeship',
          'Company Training',
          'Paid Course',
          'Self-taught'
        ]
      },
    }
  },
  computed: {
    paymentPopup() {
      return this.$store.getters.paymentPopup;
    },
    userEmail() {
      return this.$store.getters.getUserEmail;
    },
    language() {
      return this.$store.getters.getLanguage;
    }
  },
  methods: {
    signUpCloseBtn() {
      this.$store.commit("togglePopup", {popup: "signUpFormPopup", status: false})
    },
    selectOption(type, option) {
      if (type === 'gender') {
        this.genderSelected = option;
        this.genderHide = true;
      }
      if (type === 'nationality') {
        this.nationalitySelected = option;
        this.nationalityHide = true;
      }
      if (type === 'profession') {
        this.professionSelected = option;
        this.professionHide = true;
      }
      if (type === 'country') {
        this.countrySelected = option;
        this.countryHide = true;
      }
      if (type === 'howStart') {
        this.howStartSelected = option;
        this.howStartHide = true;
      }
    },
    openOptions(option) {
      if (option === 'gender') {
        this.genderHide = !this.genderHide;
      }
      if (option === 'nationality') {
        this.nationalityHide = !this.nationalityHide;
      }
      if (option === 'profession') {
        this.professionHide = !this.professionHide;
      }
      if (option === 'country') {
        this.countryHide = !this.countryHide;
      }
      if (option === 'howStart') {
        this.howStartHide = !this.howStartHide;
      }
    },
    allInputChecker() {
      let allInputCheck;
      this.englishNameWarningText = false;
      this.artisNameWarningText = false;
      this.passportWarningText = false;
      this.contactWarningText = false;
      this.studioAddressWarningText = false;
      this.studioNameWarningText = false;
      this.portfolioWarningText = false;
      this.genderWarningText = false;
      this.nationalityWarningText = false;
      this.professionWarningText = false;
      this.countryWarningText = false;
      this.howStartWarningText = false;
      const emptyChecks = this.submitEmptyCheck();
      if(emptyChecks) {
        allInputCheck = true;
        console.log("All input check passed!");
        return allInputCheck;
      }
    },
    submitEmptyCheck() {
      const textInput = document.querySelectorAll('.textInput');
      let englishNameCheck;
      // let chineseNameCheck;
      let artisNameCheck;
      // let birthDayCheck;
      // let birthMonthCheck;
      // let birthYearCheck;
      let passportCheck;
      let contactCheck;
      let studioNameCheck;
      let addressCheck;
      let portfolioCheck;
      // let genderCheck;
      let nationalityCheck;
      let professionCheck;
      let countryCheck;
      // let howStartCheck;
      let allInputCheck;

      textInput.forEach(input => {
        if(!input.value) {
          console.log(input.name + "Input field empty");
          if(input.name === "englishName") {
            this.englishNameWarningText = true;
          }
          // if(input.name === "chineseName") {
          //   this.chineseNameWarningText = true;
          // }
          if(input.name === "artisName") {
            this.artisNameWarningText = true;
          }
          if(input.name === "passport") {
            this.passportWarningText = true;
          }
          if(input.name === "contact") {
            this.contactWarningText = true;
          }
          if(input.name === "studioName") {
            this.studioNameWarningText = true;
          }
          if(input.name === "studioAddress") {
            this.studioAddressWarningText = true;
          }
          if(input.name === "portfolio") {
            this.portfolioWarningText = true;
          }
          // if(input.name === "birthDay" || input.name === "birthMonth" || input.name === "birthYear") {
          //   this.birthWarningText = true;
          // }
        }
        else {
          if(input.name === "englishName") {
            englishNameCheck = true;
            // return englishNameCheck;
          }
          // if(input.name === "chineseName") {
          //   chineseNameCheck = true;
          //   console.log("chineseNameCheck Passed");
          //   return chineseNameCheck;
          // }
          if(input.name === "artisName") {
            artisNameCheck = true;
          }
          if(input.name === "passport") {
            passportCheck = true;
          }
          if(input.name === "contact") {
            contactCheck = true;
          }
          if(input.name === "studioName") {
            studioNameCheck = true;
          }
          if(input.name === "studioAddress") {
            addressCheck = true;
          }
          if(input.name === "portfolio") {
            portfolioCheck = true;
          }
          // if(input.name === "birthDay") {
          //   birthDayCheck = true;
          //   console.log("birthDayCheck Passed");
          //   return birthDayCheck;
          // }
          // if(input.name === "birthMonth") {
          //   birthMonthCheck = true;
          //   console.log("birthMonthCheck Passed");
          //   return birthMonthCheck;
          // }
          // if(input.name === "birthYear") {
          //   birthYearCheck = true;
          //   console.log("birthYearCheck Passed");
          //   return birthYearCheck;
          // }
        }
      })

      // if (this.genderSelected === "Please select..." || "") {
      //   this.genderWarningText = true;
      // } else {
      //   genderCheck = true;
      //   return genderCheck;
      // }

      if (this.nationalitySelected === "Please select..." || "") {
        this.nationalityWarningText = true;
      } else {
        nationalityCheck = true;
        // return nationalityCheck;
      }

      if (this.professionSelected === "Please select..." || "") {
        this.professionWarningText = true;
      } else {
        professionCheck = true;
        // return professionCheck;
      }

      if (this.countrySelected === "Please select..." || "") {
        this.countryWarningText = true;
      } else {
        countryCheck = true;
        // return countryCheck;
      }

      // if (this.howStartSelected === "Please select..." || "") {
      //   this.howStartWarningText = true;
      // } else {
      //   howStartCheck = true;
      //   return howStartCheck;
      // }

      if(englishNameCheck && artisNameCheck && passportCheck && contactCheck && studioNameCheck && addressCheck && portfolioCheck && nationalityCheck && professionCheck && countryCheck) {
        allInputCheck = true;
        return allInputCheck;
      }
    },
    emailCheck() {
      // let LetterRegExp = /^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+$/g;
      let emailCheck;
      if(!this.email) {
        this.emailWarningText = true;
      }
      else {
        if(!this.email.includes("@") || !this.email.includes(".")) {
          this.emailWarningText2 = true;
        }
        else {
          emailCheck = true;
          this.checkEmail = true;
          return emailCheck;
        }
      }
    },
    confirmEmailCheck() {
      let confirmEmailCheck;
      if(this.confirmEmail === this.email) {
        if(!this.confirmEmail) {
          this.confirmEmailWarningText = true;
        }
        else {
          confirmEmailCheck = true;
          return confirmEmailCheck;
        }
      }
      else {
        this.confirmEmailWarningText2 = true;
      }
    },
    passwordCheck() {
      // let LetterRegExp = /^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+$/g;
      let passwordCheck;
      if(!this.password) {
        this.passwordWarningText = true;
      }
      else {
        if(this.password.length < 8) {
          this.passwordWarningText2 = true;
        }
        else {
          passwordCheck = true;
          this.checkPassword = true;
          return passwordCheck;
        }
      }
    },
    confirmPasswordCheck() {
      let confirmPasswordCheck;
      if(this.confirmPassword === this.password) {
        if(!this.confirmPassword) {
          this.confirmPasswordWarningText = true;
        }
        else {
          confirmPasswordCheck = true;
          return confirmPasswordCheck;
        }
      }
      else {
        this.confirmPasswordWarningText2 = true;
      }
    },
    uploadUserData() {
      // let options = { day: 'numeric', month: 'long', year: 'numeric' };
      const timestamp = firebase.firestore.Timestamp.now().seconds;
      // let timestamp = "063812789146";
      const today = new Date();
      const month = today.getMonth();
      let expiryDate = null;
      if(month === 0 || month === 2 || month === 4 || month === 6 || month === 7 || month === 9 || month === 11) {
        expiryDate = timestamp.seconds + 2592000;
      }
      else {
        expiryDate = timestamp.seconds + 2505600;
      }
      // const expiryDateConver = serverDate.seconds;
      this.$store.state.main.expiryDate = timestamp;
      db.collection("user_data").doc(this.email).set({
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        studioName: this.studioName,
        studioAdress: this.studioAddress,
        signUpDate: timestamp,
        courseExpiryDate: expiryDate,
        lectureProgress: 0,
        lectureComplete: false,
        examStatus: 0,
        examResult: 0,
        examComplete: false,
        examCompleteDate: null,
        payment: false,
        examTempScore: 0,
        instagram: this.instagram,
        facebook: this.facebook,
        mewe: this.mewe
      })
      .then(() => {
          console.log("Document successfully written!");
      })
      .catch((error) => {
          console.error("%cError writing document: ", error,"color:red;font-size:15pt;");
      });
    },
    submitSignUpForm() {
      this.englishNameWarningText = false;
      this.artisNameWarningText = false;
      this.passportWarningText = false;
      this.contactWarningText = false;
      this.studioAddressWarningText = false;
      this.studioNameWarningText = false;
      this.portfolioWarningText = false;
      this.genderWarningText = false;
      this.nationalityWarningText = false;
      this.professionWarningText = false;
      this.countryWarningText = false;
      this.howStartWarningText = false;
      const inputCheck = this.submitEmptyCheck();
      if(inputCheck) {
        console.log('check passed');
        this.updateAccount()
        // this.$store.commit("togglePopup", {popup: "paymentPopup", status: true})
      } else {
        console.log('check NOT passed');
      }
    },
    paymentPopupToggle() {
      const allInputCheck = this.allInputChecker();
      if(allInputCheck) {
        if (!this.paymentPopup) {
          this.$store.commit("paymentPopup", true)
        }
      }
      else {
        console.log("%cInput check didn't pass","color:red;font-size:15pt;");
      }
    },
    updateAccount() {
      let userData = db.collection("user_data").doc(this.userEmail)
      return userData
      .update({
        "englishName": this.englishName,
        "chineseName": this.chineseName,
        "artisName": this.artisName,
        "birth": this.birth,
        "gender": this.genderSelected,
        "passport": this.passport,
        "nationality": this.nationalitySelected,
        "contact": this.contact,
        "profession": this.professionSelected,
        "studioName": this.studioName,
        "studioAddress": this.studioAddress,
        "shopCountry": this.countrySelected,
        "yearOfExp": this.yearOfExp,
        "howYouStart": this.howStartSelected,
        "portfolio": this.portfolio,
      })
      .then(() => {
        console.log("Account data updated");
        this.$store.commit("togglePopup", {popup: "paymentPopup", status: true})
      })
      .catch((err) => {
        console.log("Account data updated error: " + err);
      })
    },
    async checkUserData() {
      const userData = await dbFunctions.getDataByUser(this.userEmail);
      this.englishName = userData.englishName ?? null
      this.chineseName = userData.chineseName ?? null
      this.artisName = userData.artisName ?? null
      this.birth = userData.birth ?? null
      this.genderSelected = userData.gender ?? null
      this.passport = userData.passport ?? null
      this.nationalitySelected = userData.nationality ?? null
      this.contact = userData.contact ?? null
      this.professionSelected = userData.profession ?? null
      this.studioName = userData.studioName ?? null
      this.studioAddress = userData.studioAddress ?? null
      this.countrySelected = userData.shopCountry ?? null
      this.yearOfExp = userData.yearOfExp ?? null
      this.howStartSelected = userData.howYouStart ?? null
      this.portfolio = userData.portfolio ?? null
    }
  },
  mounted() {
    this.checkUserData()
  }
}
</script>

<style lang="scss" scoped>
@import '~@/components/form/form.scss';
</style>
