import firebase from "firebase";

const state = {
  firstLoad: true,
  popup: false,
  homeCallToAction: false,
  popups: {
    menuPopup: false,
    aboutPopup: false,
    certListPopup: false,
    loginPopup: false,
    accountPopup: false,
    coursePopup: false,
    passwordResetPopup: false,
    termsAndConditionsPopup: false,
    signUpFormPopup: false,
    contactPopup: false,
    paymentPopup: false,
    coursePurchaseForm: false,
    faqPopup: false
  },
  loggedIn: false,
  paymentPopupPg: "paymentMethods",
  paymentStatus: "fail",
  paymentFor: "",
  userData: [],
  userEmail: null,
  expiryDate: null,
  accountPage: "overview",
  lecturePg: 1,
  language: "zh",
  examPg: 0,
  examAnswered: false,
  explainPopup: false,
  testData: []
};

const getters = {
  getFirstLoad: state => state.firstLoad,
  popup: state => state.popup,
  homeCallToAction: state => state.homeCallToAction,
  menuPopup: state => state.popups.menuPopup,
  aboutPopup: state => state.popups.aboutPopup,
  certListPopup: state => state.popups.certListPopup,
  loginPopup: state => state.popups.loginPopup,
  passwordResetPopup: state => state.popups.passwordResetPopup,
  accountPopup: state => state.popups.accountPopup,
  coursePopup:  state => state.popups.coursePopup,
  termsAndConditionsPopup: state => state.popups.termsAndConditionsPopup,
  signUpFormPopup: state => state.popups.signUpFormPopup,
  contactPopup: state => state.popups.contactPopup,
  faqPopup: state => state.popups.faqPopup,
  coursePurchaseForm: state => state.popups.coursePurchaseForm,
  paymentPopup: state => state.popups.paymentPopup,
  paymentPopupPg: state => state.paymentPopupPg,
  getPaymentStatus: state => state.paymentStatus,
  paymentFor: state => state.paymentFor,
  getUserEmail: state => state.userEmail,
  getLoginStatus: state => state.loggedIn,
  getAccountPage: state => state.accountPage,
  getUserData: state => state.userData,
  getLecturePg: state => state.lecturePg,
  getExamPg: state => state.examPg,
  getLanguage: state => state.language,
  getExamResult: state => state.userData.examResult,
  getExamTempScore: state => state.userData.examTempScore,
  getExamAnswered: state => state.examAnswered,
  getExplainPopup: state => state.explainPopup
};

const mutations = {
  togglePopup(state, payload) {
    const allPopup = state.popups;
    for (const key in allPopup) {
      if(key === payload.popup) {
        allPopup[key] = payload.status;
        state.popup = payload.status;
      } else {
        allPopup[key] = false;
      }
    }
  },
  aboutPopup(state, data) {
    state.aboutPopup = data;
    state.popup = data;
    if(data === true) {
      state.loginPopup = false;
      state.accountPopup = false;
      state.signUpFormPopup = false;
      state.homeCallToAction = false;
    }
  },
  loginPopup(state, data) {
    state.loginPopup = data;
    state.popup = data;
    if(data === true) {
      state.aboutPopup = false;
      state.accountPopup = false;
      state.signUpFormPopup = false;
      state.passwordResetPopup = false;
      state.homeCallToAction = false;
    }
  },
  passwordResetPopup(state, data) {
    state.passwordResetPopup = data;
    state.popup = data;
    if(data === true) {
      state.loginPopup = false;
      state.aboutPopup = false;
      state.accountPopup = false;
      state.signUpFormPopup = false;
      state.homeCallToAction = false;
    }
  },
  accountPopup(state, data) {
    state.accountPopup = data;
    state.popup = data;
    if(data === true) {
      state.aboutPopup = false;
      state.loginPopup = false;
      state.signUpFormPopup = false;
      state.paymentPopup = false;
      state.homeCallToAction = false;
    }
  },
  accountPage(state, data) {
    state.accountPage = data;
    console.log();
  },
  signUpFormPopup(state, data) {
    state.signUpFormPopup = data;
    state.popup = data;
    if(data === true) {
      state.aboutPopup = false;
      state.accountPopup = false;
      state.loginPopup = false;
      state.homeCallToAction = false;
    }
  },
  paymentPopup(state, data) {
    state.paymentPopup = data;
    state.popup = data;
    if(data === true) {
      state.aboutPopup = false;
      state.accountPopup = false;
      state.loginPopup = false;
      state.signUpFormPopup = false;
      state.homeCallToAction = false;
    }
  },
  updatePaymentFor(state, data) {
    state.paymentFor = data
  },
  changePaymentPopup(state, data) {
    state.paymentPopupPg = data;
  },
  changePaymentStatus(state, data) {
    state.paymentStatus = data;
  },
  updateUserData(state, data) {
    state.userData = data;
  },
  removeUserData(state) {
    state.userData = [];
  },
  updateTestData(state, data) {
    state.testData = data;
  },
  updateUserEmail(state, data) {
    state.userEmail = data;
  },
  updateLoginStatus(state, data) {
    state.loggedIn = data;
    if(data === false) {
      state.userData = [];
      state.firstLoad = true;
    }
  },
  updateFirstLoad(state, data) {
    state.firstLoad = data;
  },
  closeAllPopup(state) {
    state.popup = false;
    state.popups.loginPopup = false;
    state.popups.aboutPopup = false;
    state.popups.accountPopup = false;
    state.popups.signUpFormPopup = false;
  },
  changeLecturePg(state, data) {
    state.lecturePg = data;
  },
  changeLanguage(state, data) {
    state.language = data;
  },
  changeExamPg(state, data) {
    state.examPg = data;
  },
  examAnswered(state, data) {
    state.examAnswered = data;
  },
  changeExplainPopup(state, data) {
    state.explainPopup = data;
  },
};

const actions = {
  updateUserData({commit, state}, data) {
    commit('updateUserData', data);
    // commit('updateTestData', doc.data());
    if (state.firstLoad) {
      commit("togglePopup", {popup: "accountPopup", status: true})
      state.firstLoad = false;
    } else {
      state.firstLoad = false;
    }
  },

  async submitLogin({commit}, data) {
    try {
      const user = await firebase.auth().signInWithEmailAndPassword(data.email, data.password);
      // this.$store.commit("updateUserData", user.user)
      // this.$store.commit("loginPopup", false)
      // if (user.) {

      // }
      commit("updateUserEmail", user.user.email);
      // this.$store.commit("accountPopup", true)
      return true;
    }
    catch(err) {
      console.log(err);
      alert(err);
      return false;
    }
  },

  logout({commit}) {
    firebase
    .auth()
    .signOut()
    .then(() => {
      commit("togglePopup", {popup: "accountPopup", status: false})
      commit("removeUserData")
      console.log("Logged Out");
    })
    .catch(err => {
      console.log(err);
      alert("err");
    });
  },

  makePayment({commit}, data) {
    commit("updatePaymentFor", data)
    commit("togglePopup", {popup: "paymentPopup", status: true})
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
